"use client";

import { useState } from "react";
import { CopyIcon } from "lucide-react";
import { cn } from "@repo/ui/utils";
import type { Send, Step } from "../../step-machine";

export function DeliveryStep({ send, step }: { send: Send; step: Step }) {
  if (step !== "delivery") {
    return null;
  }
  return (
    <div className="grid justify-items-center gap-2">
      <p className="text-center font-sans text-sm">
        <span>Congrats! </span>
        <span className="font-semibold text-yellow-100">General Mort </span>
        <span>
          won your Auction! Add the buyer on Battle.net to complete the
          transaction.
        </span>
      </p>
      <BattleId />
      <p>
        <button
          className="block h-[27px] w-[120px] font-diablo text-[10px] uppercase hover:scale-105  hover:shadow-md hover:shadow-green-100/10"
          style={{
            borderImage: "url('/assets/buttons/button-green.webp') stretch",
            borderImageWidth: "auto",
            borderImageSlice: "110 0 0 0",
          }}
          onClick={() => {
            send("NEXT");
          }}
        >
          Verify
        </button>
      </p>
    </div>
  );
}

function BattleId() {
  const battleId = "Morten#11928";
  const [copied, setCopied] = useState(false);
  const [showMsg, setShowMsg] = useState(false);

  return (
    <div className="h-8">
      <p className="flex items-center gap-2 font-diablo">
        <span>{battleId}</span>
        <button
          onClick={() => {
            void (async () => {
              await navigator.clipboard.writeText(battleId);
              setCopied(true);
              setShowMsg(true);
              setTimeout(() => {
                setShowMsg(false);
              }, 2000);
            })();
          }}
        >
          <CopyIcon className="h-4 w-4" />
        </button>
      </p>
      {copied && (
        <p
          className={cn("hidden text-center font-sans text-xs text-gray-100", {
            block: showMsg,
          })}
        >
          Battle.net ID Copied
        </p>
      )}
    </div>
  );
}
