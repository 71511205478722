"use client";

import { useState } from "react";
import { PlusIcon, MinusIcon, PenLineIcon } from "lucide-react";
import { CoinIcon } from "@/components/icons/coins-icon";
import { cn } from "@repo/ui/utils";
import type { Send, Step } from "../../step-machine";

type Increment = 0.01 | 0.02 | 0.05 | 0.1;

export function IntialStep({
  step,
  children,
}: {
  step: Step;
  children?: React.ReactNode;
}) {
  if (step !== "initial") {
    return null;
  }
  return <div className="grid  justify-items-center gap-3">{children}</div>;
}

IntialStep.Bid = Bid;
IntialStep.Buyout = Buyout;

function Bid({ send }: { send: Send }) {
  const intialValue = 200000000;
  const [value, setValue] = useState<number>(intialValue);
  const [editing, setEditing] = useState(false);
  const [increment, setIncrement] = useState<Increment>(0.01);
  return (
    <div className="grid justify-items-center gap-1">
      <div className="flex items-end">
        <div className="flex h-7 items-center">
          <button
            type="button"
            disabled={!editing}
            className={cn(
              "mr-1 flex h-4 w-4 items-center justify-center rounded-sm bg-red-200 transition-all  delay-100  [&:not(:disabled)]:hover:scale-125",
              {
                "opacity-50": !editing,
              },
            )}
            onClick={() => {
              setValue((prev) => {
                return Math.max(0, prev - prev * increment);
              });
            }}
          >
            <MinusIcon className="h-3 w-3" />
          </button>
        </div>
        <div>
          <h2 className="mb-1 text-center font-diablo text-sm uppercase">
            BID
          </h2>
          <button
            type="button"
            disabled={!editing}
            className=" flex h-7 w-[120px] items-center justify-center font-diablo text-[16px]"
            onClick={() => {
              send("BID");
            }}
            style={{
              borderImage: "url('/assets/buttons/button-blue.webp') stretch",
              borderImageWidth: "auto",
              borderImageSlice: "110 0 0 0",
            }}
          >
            <CoinIcon className="w- mr-1 h-3 text-yellow-100" />
            {Intl.NumberFormat("en", { notation: "compact" }).format(value)}
          </button>
        </div>
        <div className="flex h-7 items-center">
          <button
            type="button"
            onClick={() => {
              setValue((prev) => {
                return Math.max(0, prev + prev * increment);
              });
            }}
            disabled={!editing}
            className={cn(
              "ml-1 flex h-4 w-4 items-center justify-center rounded-sm bg-green-200 transition-all  delay-100 [&:not(:disabled)]:hover:scale-125",
              {
                "opacity-50": !editing,
              },
            )}
          >
            <PlusIcon className="h-3 w-3" />
          </button>
        </div>
      </div>
      <div className="grid gap-1">
        <div className="flex items-center gap-1">
          <IncrementBtn
            selected={increment === 0.01}
            onClick={() => {
              setIncrement(0.01);
            }}
          >
            1x
          </IncrementBtn>
          <IncrementBtn
            selected={increment === 0.02}
            onClick={() => {
              setIncrement(0.02);
            }}
          >
            2x
          </IncrementBtn>
          <IncrementBtn
            selected={increment === 0.05}
            onClick={() => {
              setIncrement(0.05);
            }}
          >
            5x
          </IncrementBtn>
          <IncrementBtn
            selected={increment === 0.1}
            onClick={() => {
              setIncrement(0.1);
            }}
          >
            10x
          </IncrementBtn>

          <button
            type="button"
            className="flex h-7 w-7 items-center justify-center rounded-sm border border-gray-600 bg-gray-600 text-xs  hover:bg-gray-400"
            onClick={() => {
              setEditing((prev) => !prev);
            }}
          >
            <PenLineIcon className="h-4 w-4" />
          </button>
        </div>
        <p className="text-center font-sans text-xs">
          <span className="text-gray-200">Increment:</span>
          <span className="text-gray-100">
            +
            {Intl.NumberFormat("en", { notation: "compact" }).format(
              intialValue * increment,
            )}
          </span>
        </p>
      </div>
    </div>
  );
}

function IncrementBtn({
  selected,
  children,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
  selected: boolean;
}) {
  return (
    <button
      {...rest}
      type="button"
      className={cn(
        "h-7 w-7 rounded-sm border border-gray-600 bg-gray-600 font-sans text-xs transition-all duration-200 disabled:opacity-75  [&:not(:disabled)]:hover:border-gray-200",
        {
          "border border-gray-200": selected,
        },
      )}
    >
      {children}
    </button>
  );
}

function Buyout({ value, send }: { value: number; send: Send }) {
  return (
    <div>
      <h2 className="mb-1 text-center font-diablo text-sm uppercase">Buyout</h2>
      <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={() => {
            send("BUYOUT");
          }}
          className=" flex h-7 w-[118px] items-center justify-center font-diablo text-[16px]  hover:scale-105 hover:shadow-md hover:shadow-green-100/10"
          style={{
            borderImage: "url('/assets/buttons/button-green.webp') stretch",
            borderImageWidth: "auto",
            borderImageSlice: "110 0 0 0",
          }}
        >
          <CoinIcon className="w- mr-1 h-3 text-yellow-100" />
          {Intl.NumberFormat("en", { notation: "compact" }).format(value)}
        </button>
      </div>
    </div>
  );
}
